import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '../services/nav.service';
//import { DOCUMENT } from '@angular/common';

@Component({
    templateUrl: './templates/sitemap.html',
})
export class SitemapComponent implements OnInit {
//    @ViewChild('container','test')
//     private container: ElementRef;

    title = 'Sitemap';
    description = '';
    keywords = '';
    engine = '';
    product = '';
    subModule = '';
    pageRoute = '/sitemap';
    engineRoute = '';
    productRoute = '';
    subModuleRoute = '';

    constructor(
        public navService: NavService
    ) {}

    ngOnInit() {
        this.navService.goToAnchor('#Sitemap');
    }

    changeEngine(engine, route) {
        this.engine = engine;
        this.engineRoute = route;
    }
}