import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { SitemapComponent } from './sitemap/sitemap.component';
import { FourOhFourComponent }  from './404/404.component';

const routes: Routes = [
    {
        path: 'admin',
        loadChildren: 'app/admin/admin.module#AdminModule'
    },
    {
        path: '',
        loadChildren: 'app/homepage/home.module#HomeModule'
    },
    {
        path: 'news',
        loadChildren: 'app/blog/blog.module#BlogModule'
    },
    {
        path: 'demo',
        loadChildren: 'app/demo/demo.module#DemoModule'
    },
    {
        path: 'bank_operations',
        loadChildren: 'app/archived-pages/archived-bank-op-pages.module#ArchivedBankOpPagesModule'
    },
    {
        path: 'central-bank-operations',
        loadChildren: 'app/archived-pages/archived-cbank-op-pages.module#ArchivedCBankOpPagesModule'
    },
    {
        path: 'investment-management',
        loadChildren: 'app/archived-pages/archived-investment-pages.module#ArchivedInvestmentPagesModule'
    },
    {
        path: 'loans',
        loadChildren: 'app/archived-pages/archived-loans-pages.module#ArchivedLoansPagesModule'
    },
    {
        path: 'general-operations',
        loadChildren: 'app/archived-pages/archived-general-op-pages.module#ArchivedGeneralOpPagesModule'
    },
    {
        path: 'real-estate',
        loadChildren: 'app/archived-pages/archived-real-estate-pages.module#ArchivedRealEstatePagesModule'
    },
    {
        path: 'services-and-software-features',
        loadChildren: 'app/archived-pages/archived-service-and-feature-pages.module#ArchivedServiceandFeaturePagesModule'
    },
    { 
        path: 'sitemap', 
        component: SitemapComponent
    },
    {
        path: '**',
        component: FourOhFourComponent
    }
];

export const routing = RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules});