import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Headers, Http } from '@angular/http';
import { Subject } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';

import { PageScrollConfig, PageScrollService, PageScrollInstance } from 'ng2-page-scroll';

@Injectable()
export class NavService {
  sectionInView;
  engine = '';
  product;
  title;
  description;
  subModule;
  engineRoute;
  productRoute;
  subModuleRoute;
  url;
  scrollToComponent;
  currentPane = '';
  contactOpen;

  // Observable string sources
  private sectionInViewChangeConfirmedSource = new Subject<string>();
  private dataChangeConfirmedSource = new Subject<string>();
  private engineChangeConfirmedSource = new Subject<string>();
  private productChangeConfirmedSource = new Subject<string>();
  private subModuleChangeConfirmedSource = new Subject<string>();
  private engineRouteChangeConfirmedSource = new Subject<string>();
  private productRouteChangeConfirmedSource = new Subject<string>();
  private subModuleRouteChangeConfirmedSource = new Subject<string>();
  private contactOpenConfirmedSource = new Subject<boolean>();
  private formContactTypeChangeConfirmedSource = new Subject<string>();
  private formProductChangeConfirmedSource = new Subject<string>();
  private detailTabChangeConfirmedSource = new Subject<string>();
  // private scrollTriggerConfirmedSource = new Subject<string>();

  // Observable string streams
  // dataChangeConfirmed$ = this.dataChangeConfirmedSource.asObservable();
  sectionInViewChange$ = this.sectionInViewChangeConfirmedSource.asObservable();
  dashSwitch$ = this.dataChangeConfirmedSource.asObservable();
  engineChange$ = this.engineChangeConfirmedSource.asObservable();
  subModuleChange$ = this.subModuleChangeConfirmedSource.asObservable();
  productChange$ = this.productChangeConfirmedSource.asObservable();
  engineRouteChange$ = this.engineRouteChangeConfirmedSource.asObservable();
  productRouteChange$ = this.productRouteChangeConfirmedSource.asObservable();
  subModuleRouteChange$ = this.subModuleRouteChangeConfirmedSource.asObservable();
  contactOpen$ = this.contactOpenConfirmedSource.asObservable();
  contactformContactTypeChange$ = this.formContactTypeChangeConfirmedSource.asObservable();
  contactformProductChange$ = this.formProductChangeConfirmedSource.asObservable();
  detailTabChange$ = this.detailTabChangeConfirmedSource.asObservable();
  // scrollTrigger$ = this.scrollTriggerConfirmedSource.asObservable();

  scrollSpy(sectionInView: string) {    
    this.sectionInView = sectionInView;
    this.sectionInViewChangeConfirmedSource.next(sectionInView);
  }

  constructor(
    private http: Http,
    private titleService: Title,
    private metaService: Meta,
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any
  ) {
    PageScrollConfig.defaultScrollOffset = 35;
    PageScrollConfig.defaultDuration = 250;
  }

  paneSet(value) {
    console.log('entered');
    return this.currentPane === value;
  }

  setPane(value) {
    console.log('entered');
    return this.currentPane = value;
  };

  dashPaneChange(currentPane: string) {
    console.log('entered');
    this.currentPane = currentPane;
    // this.dataChangeConfirmedSource.next(currentPane);
  }
  engineChange(engine: string, engineRoute: string, description: string, keywords: string) {
    console.log('entered');
    this.engine = engine;
    this.engineRoute = engineRoute;
    this.product = '';
    this.productRoute = '';
    this.subModule = '';
    this.subModuleRoute = '';
    this.engineChangeConfirmedSource.next(engine);
    this.engineRouteChangeConfirmedSource.next(engineRoute);
    this.metaChange(this.engine, description, keywords);
  }
  productChange(product: string, productRoute: string, description: string, keywords: string) {
    console.log('entered');
    this.product = product;
    this.productRoute = productRoute;
    this.subModule = '';
    this.subModuleRoute = '';
    this.productChangeConfirmedSource.next(product);
    this.productRouteChangeConfirmedSource.next(productRoute);
    this.metaChange(this.product, description, keywords);
  }

  subModuleChange(subModule: string, subModuleRoute: string, product: string, productRoute: string, description: string, keywords: string) {
    this.product = product;
    console.log('entered');
    this.productRoute = productRoute;
    this.subModule = subModule;
    this.subModuleRoute = subModuleRoute;
    this.productChangeConfirmedSource.next(product);
    this.productRouteChangeConfirmedSource.next(productRoute);
    this.subModuleChangeConfirmedSource.next(subModule);
    this.subModuleRouteChangeConfirmedSource.next(subModuleRoute);
    this.metaChange(this.subModule, description, keywords);
  }

  resetAll() {
    console.log('entered');
    this.product = '';
    this.productRoute = '';
    this.subModule = '';
    this.subModuleRoute = '';
    this.productChangeConfirmedSource.next(this.product);
    this.productRouteChangeConfirmedSource.next(this.productRoute);
    this.subModuleChangeConfirmedSource.next(this.subModule);
    this.subModuleRouteChangeConfirmedSource.next(this.subModuleRoute);
    this.metaChange(this.subModule, '', '');
  }

  metaChange(title, description, keywords, prerenderCode?): void {
    console.log('entered');
    console.log(title, description, keywords, prerenderCode);
    if (title === 'MIMICS Financial Software' || !title) {
      this.title = 'MIMICS Financial Software';
    } else {
      this.title = title + ' | MIMICS Financial Software';
    }

    //check for window object first (can throw error in ssr)
    this.url = window ? window.location.href : 'https://www.mimics.com';

    this.description = description;

    this.titleService.setTitle(this.title);

    this.metaService.updateTag({ content: this.title }, "name='twitter:title'");
    this.metaService.updateTag({ content: keywords }, "name='keywords'");
    this.metaService.updateTag({ content: this.description }, "name='description'");
    this.metaService.updateTag({ content: this.description }, "name='twitter:description'");
    this.metaService.updateTag({ content: this.title }, "property='og:title'");
    this.metaService.updateTag({ content: this.description }, "property='og:description'");
    this.metaService.updateTag({ content: this.url }, "property='og:url'");
    if (prerenderCode) { this.setHttpStatusCode(prerenderCode); }
  }

  setHttpStatusCode(code: string = '200'): void {
    this.metaService.updateTag({ content: code, name: 'prerender-status-code' });
  }

  openContact(status: boolean) {
    console.log('entered');
    this.contactOpen = status;
    this.contactOpenConfirmedSource.next(status);
  }

  changeFormContactType(formContactTypeChange: string) {
    console.log('entered');
    this.formContactTypeChangeConfirmedSource.next(formContactTypeChange);
  }

  changeFormProduct(formProductChange: string) {
    console.log('entered');
    this.formProductChangeConfirmedSource.next(formProductChange);
  }

  openContactDefault() {
    console.log('entered');
    this.openContact(true);
    this.changeFormContactType('Contact Type');
    this.changeFormProduct('I am Interested In...');
  }

  openContactProductDemo(product, evt?) {
    console.log('entered');
    if (evt) { evt.preventDefault(); }
    this.openContact(true);
    this.changeFormContactType('Request a Demo');
    this.changeFormProduct(product);
  }

  openContactProductMoreInfo(product, evt?) {
    console.log('entered');
    if (evt) { evt.preventDefault(); }
    this.openContact(true);
    this.changeFormContactType('Request Info');
    this.changeFormProduct(product);
  }

  changeDetailTab(detailTabChange: string) {
    console.log('entered');
    this.detailTabChangeConfirmedSource.next(detailTabChange);
  }

  goToAnchor(anchorName) {
    console.log('entered');
    let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, anchorName);
    this.pageScrollService.start(pageScrollInstance);
  };
}