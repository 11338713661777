import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';

import { Ng2DeviceService } from 'ng2-device-detector';

import { NavService } from './services/nav.service';
import { SearchService } from './shared/search.service';

import { LatestBrowserVersions } from './shared/browser-versions';

@Component({
    selector: 'mimics-homepage',
    templateUrl: './templates/top-level-template.html',
    styles: [`.alert {margin-bottom: 0rem;}`],
})
export class AppComponent implements OnInit {
    modules;
    error: any;
    showHomeHeader: boolean = true;
    showHomeFooter: boolean = false;
    stickHomeHeader: boolean = false;
    routeSubscription: Subscription;
    deviceInfo: any;
    browserver: number;
    browsername: string;
    browserWarningOpen: boolean = false;

    constructor(
        public location: Location,
        public router: Router,
        public renderer: Renderer2,
        public searchService: SearchService,
        private _deviceService: Ng2DeviceService,
        public navService: NavService
    ) {
        this.checkBrowserVersion();
        searchService.svcRenderer = renderer;
    }

    checkBrowserVersion() {
        console.log('entered');
      this.deviceInfo = this._deviceService.getDeviceInfo();
      this.browserver = parseInt(this.deviceInfo.browser_version);
      this.browsername = this.deviceInfo.browser;
      if(LatestBrowserVersions[this.browsername] - 2 >= this.browserver) {
          this.browserWarningOpen = true;
      }
    }

    ngOnInit() {
        this.routeSubscription = this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(event => {
                /*
                the homepage header/footer only really seem to apply to potential clients.  this hides them on any 'admin' related route- we can also use this to swap it out for an 'admin-related' header if necessary
                */
               console.log('entered');
                this.showHomeHeader = !event['url'].includes('admin');
                // this.showHomeHeader = event['url'];
                this.stickHomeHeader = event['url'] !== '/';
                // console.log("showHomeHeader "+ this.showHomeHeader)
                this.showHomeFooter=true;
            });
    }

    ngOnDestroy() {
        if (this.routeSubscription) { this.routeSubscription.unsubscribe(); }
    }
    
    closeBrowserWarning(){
        console.log('entered');
        this.browserWarningOpen = false;
    }
}
