import { Component, OnInit } from '@angular/core';

import { NavService } from '../services/nav.service';

@Component({
  styles: ['#quetzal404{margin:auto;}'],
  templateUrl: './templates/404.html',
})
export class FourOhFourComponent implements OnInit {

  constructor(private _navService: NavService) {  }

  ngOnInit() {
    this._navService.metaChange('Page Not Found', 'Page Not Found', '', '404');
  }
}