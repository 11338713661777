import { environment } from './environments/environment';

//pdx1 - production
//const apiUrlBase = 'https://www.mimics.com:9000';
//const demoapiUrlBase = 'https://www.mimics.com:9001/';
 //const apiUrlBase = 'https://www.mimics.com:8000';
// const serverUrlBase = 'https://www.mimics.com';
// const crawlerUrl = 'https://www.mimics.com:8078';
// const healthCheckUrlBase = 'http://pdx3.mimics.us:8079';

//pdx3 - staging
//const apiUrlBase = 'http://pdx3.mimics.us:8000';
//const apiUrlBase = 'http://localhost:56178/';
const serverUrlBase = 'http://pdx3.mimics.us:4444';
const crawlerUrl = 'http://pdx3.mimics.us:8078';
const healthCheckUrlBase = 'http://pdx3.mimics.us:8079';

//const localdemohost=`http://localhost:8000/`;
//local dev only 
//const apiUrlBase = 'http://96.89.127.57:1991';   
//const apiUrlBase = 'http://localhost:56178';
// const serverUrlBase = 'http://localhost:4444';
// const crawlerUrl = 'http://localhost:8078';
// const healthCheckUrlBase = 'http://localhost:8079';

//pdx7 -production
const apiUrlBase = 'https://www.mimics.com:9003/';
const demoapiUrlBase = 'https://www.mimics.com:9002/';
const CONFIG = {
    //blog api routes
    blogsApiUrlBase: `${apiUrlBase}/api/blogs/view`,
    blogsbodyApiUrlBase: `${apiUrlBase}/api/blogs/getblogbody`,
    blogsAddApiUrlBase: `${apiUrlBase}/api/blogs/add-blog`,
    blogsEditApiUrlBase: `${apiUrlBase}/api/blogs/edit`,
    blogsdeleteApiUrlBase: `${apiUrlBase}/api/blogs/delete`,
    categoryApiUrlBase: `${apiUrlBase}/api/blogs/category`,
    categoryAddApiUrlBase: `${apiUrlBase}/api/blogs/add-category`,
    categoryEditApiUrlBase: `${apiUrlBase}/api/blogs/category-edit`,
    categoryDeleteApiUrlBase: `${apiUrlBase}/api/blogs/category-delete`,

    profileApiUrlBase: `${apiUrlBase}/api/profile`,
    profileblogsApiUrlBase: `${apiUrlBase}/api/profileblogs`,
    usersApiUrlBase: `${apiUrlBase}/api/users`,
    addUserApiUrlBase: `${apiUrlBase}/api/add-user`,
    getSAViewGrid: `${apiUrlBase}/saviewgrid/`,
    salesRequestsApiUrlBase: `${apiUrlBase}/api/sales-requests`,
    latestsalesRequestsApiUrlBase: `${apiUrlBase}/api/latest-sales-requests`,
    demoAccessManegemant:`${apiUrlBase}/api/DemoAccessSaveorEdit`,
    demoAccessGetData:`${apiUrlBase}/api/DemoAccessGetData`,
    userSaveApiUrlBase: `${apiUrlBase}/api/UserDemoAccessSaveorEdit`,
    demoUserAccessGetData: `${apiUrlBase}/api/DemoAccessUserGetData`,
    demoUserAccessDelete: `${apiUrlBase}/api/DemoAccessDeleteUsers`,
    demoAccessDataDelete: `${apiUrlBase}/api/DemoAccessDeleteData`,
    demoAccessDataVideo: `${apiUrlBase}/api/GetDemoAccessVideoData`,
    demoAccessDataImage: `${apiUrlBase}/api/GetDemoAccessImageData`,
    demoVidedisplayData:`${apiUrlBase}/api/DemoAccessGetDataByUserId`,
    demoUserAccessVideos: `${apiUrlBase}/api/DemoAccessUserGetDataByUserId`,
    demoUserAccessLogin: `${apiUrlBase}/api/DemoAccessUserLogin`,
    DemologinApiUrlBase: `${apiUrlBase}/api/checklogin`,
    //login routes do not use api prefix
    loginApiUrlBase: `${apiUrlBase}/api/login`,
    sendemailApiUrlBase: `${apiUrlBase}/sendemail/`,
    deleteUserApiUrlBase: `${apiUrlBase}/api/users`,
    uploadFiles: `${apiUrlBase}/api/uploadFiles/`,
    DemovideoUrlpx1:`${demoapiUrlBase}/`,
    //ssr crawler api
    crawlerUrl,
    //health check
    healthCheck: healthCheckUrlBase
};

export default CONFIG;
