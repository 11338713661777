import { NgModule }       from '@angular/core';
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule, }  from '@angular/material/button';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatDialogModule }  from '@angular/material/dialog';
// import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
// import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatSliderModule } from '@angular/material/slider';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatStepperModule } from '@angular/material/stepper';
// import { MatSortModule } from '@angular/material/sort';
// import { MatTableModule } from '@angular/material/table';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatTooltipModule } from '@angular/material/tooltip';

// import { MatIconRegistry } from '@angular/material/icon';
// import { MatBadgeModule } from '@angular/material/badge';
// import { MatRippleModule } from '@angular/material/core';
// import { MatTreeModule } from '@angular/material/tree';


@NgModule({
    imports: [ 
        // MatAutocompleteModule, 
        // MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        // MatProgressSpinnerModule,
        // MatRippleModule,
        MatSelectModule,
        // MatSidenavModule,
        // MatSlideToggleModule,
        // MatSliderModule,
        MatNativeDateModule,
        // MatListModule,
        MatButtonModule,
        // MatButtonToggleModule,
        // MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatIconModule,
        MatPaginatorModule,
        // MatSidenavModule,
        // MatRadioModule,
        MatChipsModule,
        // MatExpansionModule,
        MatMenuModule,
        // MatGridListModule,
        // MatSnackBarModule,
        // MatSortModule,
        // MatStepperModule,
        // MatTableModule,
        // MatTabsModule,
        // MatToolbarModule,
        // MatTooltipModule,
        // MatRadioModule,
    ],
    exports: [ 
        // MatAutocompleteModule, 
        // MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        // MatProgressSpinnerModule,
        // MatRippleModule,
        MatSelectModule,
        // MatSidenavModule,
        // MatSlideToggleModule,
        // MatSliderModule,
        MatNativeDateModule,
        // MatListModule,
        MatButtonModule,
        // MatButtonToggleModule,
        // MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatIconModule,
        MatPaginatorModule,
        // MatSidenavModule,
        MatChipsModule,
        // MatExpansionModule,
        MatMenuModule,
        // MatGridListModule,
        // MatSnackBarModule,
        // MatSortModule,
        // MatStepperModule,
        // MatTableModule,
        // MatTabsModule,
        // MatToolbarModule,
        // MatTooltipModule,
        // MatRadioModule,
    ],
})
export class MaterialModule {}