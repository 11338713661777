import { Component, Directive, ViewChild,
        AfterViewInit, QueryList, ElementRef,
        OnChanges, Input, SimpleChange, Output, EventEmitter, OnInit
        } from '@angular/core';//, View
//import { NgFor, NgIf, NgClass } from  '@angular/common';
import { Subscription } from 'rxjs';

import { NavService } from '../services/nav.service'
import { SearchService } from '../shared/search.service';

@Component({
  selector: 'home-nav',
  templateUrl: './templates/nav.html',
})
export class NavComponent {
  sectionInViewSubscription: Subscription;
  sectionInView;
  navItems = [
    {title:'Software', anchor:'#find-your-software'},
    {title:'Options', anchor:'#module-options'},
    {title:'How We Help', anchor:'#how-we-help'},
    {title:'About Us', anchor:'#about'},
  ];
  buttonItems = [
    {title:'Log In', pane:'login', icon:'power-off'},
  ];
  public disabled:boolean = false;
  public status:{isopen:boolean} = {isopen: false};

  constructor(
    public navService: NavService,
    public searchService: SearchService
  ) { }

  sectionInViewChange(value) {
    console.log('entered', value);
    // this.inView = value;        {title:'Software', id:'software', anchor:'#software', icon:'keyboard_arrow_right'},
    // {title:'Modules Features', id:'features', anchor:'#features', icon:'keyboard_arrow_right'},
    // {title:'How We Help', id:'how-we-help', anchor:'#how-we-help', icon:'keyboard_arrow_right'},
    // console.log(value);
    this.sectionInView = value;
    // this.pageInViewClass = '';
    // if (value === 'page-top') { this.pageInView = ''; }
    // if (value === 'software' || value === 'overview-transition') { this.pageInView = 'QTZL Modules'; }
    // if (value === 'features' || value === 'crm' || value === 'accounting-rules' || value === 'interfaces') { this.pageInView = 'Module Features'; }
    // if (value === 'scalability' || value === 'ux' || value === 'accessibility') { this.pageInView = 'How We Help'; }
    // if (value === 'productivity') { this.pageInView = 'How We Help'; this.pageInViewClass = 'gold'; }
    // if (value === 'get-started') { this.pageInView = 'Get Started'; }
  }
  ngOnInit() {
    console.log('Dropdown is now: ', open);
    this.sectionInViewSubscription = this.navService.sectionInViewChange$.subscribe(sectionInView => this.sectionInViewChange(sectionInView));
  }
  ngOnDestroy() {
    if (this.sectionInViewSubscription) { this.sectionInViewSubscription.unsubscribe(); }
  }

  setPane(value) {
  
    this.navService.setPane(value);
  };
  
  paneSet(value) {
  
    return this.navService.currentPane === value;
  };
  

  public toggled(open:boolean):void {
    console.log('Dropdown is now: ', open);
  }

  public toggleDropdown($event:MouseEvent):void {
 
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }
}