import {Component} from '@angular/core';

import {LoadingService} from './loading.service';

@Component({
    selector: 'loading-component',
    template: `
        <div class="loader-wrapper" [hidden]="loadingService.loaderHidden">
        <div class="mimics-loader mx-auto">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 340 387" style="enable-background:new 0 0 340 387;" xml:space="preserve">
                <style type="text/css">
                    .st0 {
                        fill: #00C5CF;
                    }

                    .st1 {
                        fill: #FFFFFF;
                    }

                    .st2 {
                        font-family: 'MuseoSans-700';
                    }

                    .st3 {
                        font-size: 21px;
                    }
                </style>
                <g id="Layer_4">
                    <rect class="st0" width="306" height="333" />
                </g>
                <g id="Layer_5">
                    <rect class="st0" width="306" height="387" />
                    <g>
                        <path class="st1 light-mimics" d="M276.1,345.3h-20.9H245c-3.3,0-6.5-2.4-6.5-5.4s3.2-5.4,6.5-5.4h10.2H266h10.2l0,0c0,0,0,0,0.1,0
                    c2.3,0,4.7,1.2,5.8,3l5.1-2c-1.8-3.8-6-6.4-10.8-6.4c0,0,0,0-0.1,0l0,0h-31.2c-6.5,0-11.8,4.8-11.8,10.8c0,5.9,5.3,10.8,11.8,10.8
                    h21.3h9.9c3.3,0,6.5,3.2,6.5,6.5s-3.2,6.5-6.5,6.5h-9.9h-11h-10.2c-2.5,0-5-1.4-6-3.5l-4.8,2.4c1.8,3.8,6,6.4,10.8,6.4h31.2
                    c6.5,0,11.8-5.3,11.8-11.8C287.9,350.6,282.6,345.3,276.1,345.3z" />
                        <path class="st1 light-mimics" d="M221.8,360.4c-1.1,1.9-3.4,3.2-5.9,3.2h-10.2H193h-9.3c-3,0-5.4-2.4-5.4-5.4v-9v-0.3v-9c0-3,2.4-5.4,5.4-5.4
                    h9.3h12.7h10.2c2.4,0,4.7,1.2,5.8,3.1l5-2.2c-1.9-3.7-6-6.2-10.7-6.2h-32.3c-5.9,0-10.8,4.8-10.8,10.8v18.3
                    c0,5.9,4.8,10.8,10.8,10.8H216c4.8,0,9-2.6,10.8-6.4h-0.1L221.8,360.4z" />
                        <rect x="160" y="329.2" class="st1 light-mimics" width="5.4" height="39.8" />
                        <polygon class="st1 light-mimics" points="147.1,329.2 142.8,329.2 123.9,364 105.1,329.2 98.7,329.2 95.4,329.2 95.4,369 100.8,369 
                    100.8,333.2 120.2,369 121.2,369 126.6,369 127.7,369 147.1,333.2 147.1,369 152.4,369 152.4,329.2 149.2,329.2 		" />
                        <rect x="82.5" y="329.2" class="st1 light-mimics" width="5.4" height="39.8" />
                        <polygon class="st1 light-mimics" points="69.6,329.2 65.3,329.2 46.5,364 27.7,329.2 21.2,329.2 18,329.2 18,369 23.4,369 23.4,333.2 
                    42.7,369 43.8,369 49.2,369 50.3,369 69.6,333.2 69.6,369 75,369 75,329.2 71.8,329.2 		" />
                    </g>
                </g>
                <g id="Layer_6">
                    <polygon id="light-1_left" class="st1 light ray1 left" points="18,306 18,279 288,306 	" />
                    <polygon id="light-2_left" class="st1 light ray2 left" points="18,270 18,243 288,306 	" />
                    <polygon id="light-3_left" class="st1 light ray3 left" points="18,234 18,207 288,306 	" />
                    <polygon id="light-4_left" class="st1 light ray4 left" points="18,198 18,171 288,306 	" />
                    <polygon id="light-5_left" class="st1 light ray5 left" points="18,162 18,135 288,306 	" />
                    <polygon id="light-6_left" class="st1 light ray6 left" points="18,126 18,99 288,306 	" />
                    <polygon id="light-7_left" class="st1 light ray7 left" points="18,90 18,63 288,306 	" />
                    <polygon id="light-8_left" class="st1 light ray8 left" points="18,54 18,27 288,306 	" />
                </g>
                <g id="Layer_6_copy">
                    <polygon id="light-1_right" class="st1 light ray1 right" points="288,306 288,279 18,306 	" />
                    <polygon id="light-2_right" class="st1 light ray2 right" points="288,270 288,243 18,306 	" />
                    <polygon id="light-3_right" class="st1 light ray3 right" points="288,234 288,207 18,306 	" />
                    <polygon id="light-4_right" class="st1 light ray4 right" points="288,198 288,171 18,306 	" />
                    <polygon id="light-5_right" class="st1 light ray5 right" points="288,162 288,135 18,306 	" />
                    <polygon id="light-6_right" class="st1 light ray6 right" points="288,126 288,99 18,306 	" />
                    <polygon id="light-7_right" class="st1 light ray7 right" points="288,90 288,63 18,306 	" />
                    <polygon id="light-8_right" class="st1 light ray8 right" points="288,54 288,27 18,306 	" />
                </g>
                <circle class="st0" cx="326.2" cy="358.1" r="11.2" />
                <text transform="matrix(1 0 0 1 317.9619 365.8184)" class="st1 st2 st3">®</text>
            </svg>
            <h1 class="loading-text">loading ...</h1>
        </div>
        </div>
    `,
    styles: [`
        .fullscreen {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9999;
            background-color: rgba(255,255,255,0.9);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    `]
})

export class LoadingComponent {
    constructor(public loadingService: LoadingService) { }
}
