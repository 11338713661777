import CONFIG from '../../../config';

import { NavService } from '../../services/nav.service';
import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { investmentProducts, trustProducts, bankingProducts, centralBankingProducts, accountingOptions, moduleOptions } from '../../shared/available-products';
import { BackendService } from '../../shared/backend.service';
import { NotificationService } from '../../notification/notification.service';

@Component({
	selector: 'contact-form',
  templateUrl: './templates/contact-form.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactFormOpen: boolean = false;
  submitted: boolean = false;
  success: boolean = false;

  contactTypePlaceholder: string = '';
  productPlaceholder: string = 'I am Interested In...';
  discoverySourcePlaceholder: string = 'How did you hear about MIMICS?';

  contactTypes: string[] = ['Request a Demo', 'Request Info', 'Comments', 'Suggestions'];
  discoverySources: string[] = ['Referral', 'Web Search', 'Web Ad', 'Cold Call', 'Other'];

  investmentProducts: string[] = investmentProducts;
  trustProducts: string[] = trustProducts;
  bankingProducts: string[] = bankingProducts;
  centralBankingProducts: string[] = centralBankingProducts;
  accountingOptions: string[] = accountingOptions;
  moduleOptions: string[] = moduleOptions;

    availableSelectDefaults = {
      'ContactMade': this.discoverySourcePlaceholder,
      'ContactType': this.contactTypePlaceholder,
      'Products': this.productPlaceholder
    };
  
  newEntry: FormGroup;

  constructor(
      public _formBuilder: FormBuilder,
      public backendService: BackendService,
      public navService: NavService,
      public notificationService: NotificationService
  ) {
    this.setForm();
  }

  contactFormChanges(){
    this.navService.contactOpen$.subscribe(status => this.openContact(status));
    this.navService.contactformContactTypeChange$.subscribe(value => this.changeContactType(value));
    this.navService.contactformProductChange$.subscribe(value => this.changeProduct(value));
  }

  ngOnInit(){
    this.contactFormChanges();
  }

  contactToggle(){
    this.contactFormOpen = !this.contactFormOpen;
    if(this.submitted) {
      this.notificationService.hideNotification();
      this.submitted = false;
      this.setForm();
    }
    if(this.success) { this.success = false; }
  }

  onSubmit(contact) { 
    console.log(contact);
    this.submitted = true;
    if (this.newEntry.valid) {
      this.success = true;
      this.stripSelectPlaceholders(contact);
      this.backendService.postData(contact, CONFIG.salesRequestsApiUrlBase, false)
         // .map(response => JSON.parse(response))
          .subscribe(response => {
              //TODO: we return early for speed, but if server returns an error, notify dev?
              if (!response) {
                  console.log('error processing email:', response);
              }
          }, error => {
              this.contactToggle();
              this.notificationService.showNotification({
                type: 'warning',
                message: 'There was an error processing your form- please contact sales@mimics.com directly',
                timer: 0,
                closeable: true
              });
          });
    } else {
      this.notificationService.showNotification({
        type: 'error',
        message: 'Please provide required values',
        timer: 4000
      });
    }
  }

  stripSelectPlaceholders(contact) {
    //remove the initial value from select lists (useful for display, but shouldn't be included in db)
    for(let item in this.availableSelectDefaults) {
      if(contact[item] === this.availableSelectDefaults[item]) {
        contact[item] = '';
      }
    }
  }

  onReset() { 
    this.contactToggle();
    this.setForm();
  }

  setForm() {
        this.newEntry = this._formBuilder.group({
            'ContactType': [''],
            'Products': [this.productPlaceholder, Validators.required],
            'Name': ['', Validators.required],
            'CompanyName': ['', Validators.required],
            'Email': ['', [Validators.required, Validators.email]],
            'Phone': ['', Validators.required],
            'Address': [''],
            //'Address1': [''],
            'Country': [''],
            //'State': [''],
            //'City': [''],
            //'PostalCode': [''],
            'ContactMade': [this.discoverySourcePlaceholder],
            'Comments': ['']
        });
  }

  openContact(status){
    this.contactFormOpen = status;
  }

  changeContactType(value){
    this.contactFormOpen = true;
    this.newEntry.controls.ContactType.setValue(value);
  }
  
  changeProduct(value){
    this.newEntry.controls.Products.setValue(value);
  }
}