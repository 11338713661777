import {Directive, ElementRef, Input, Output, EventEmitter, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { NavService } from '../../services/nav.service';

@Directive({
    selector: '[sticky]'
})
export class Scroll implements OnInit, OnDestroy, AfterViewInit {

    @Input('sticky') stickyContent: string;
    @Input('sticky-zIndex') zIndex: number = 10;
    @Input('sticky-offset-top') offsetTop: number = 0;
    @Input('sticky-offset-bottom') offsetBottom: number = 0;
    @Input('sticky-absolute-offset') offsetAbsolute: number = 0;
    @Input('sticky-start') start: number = 0;
    @Input('sticky-start-vh') startvh: number = 0;
    @Input('sticky-class') stickClass: string = 'sticky';
    @Input('sticky-end-class') endStickClass: string = 'sticky-end';
    @Input('sticky-media-query') mediaQuery: string = '';
    @Input('sticky-parent') parentMode: boolean = false;
    @Input('scroll-spy') scrollSpy: boolean = false;
    @Input('sticky-warning') warning: number = 0;
    @Input('sticky-warning-vh') warningvh: number = 0;
    @Input('sticky-warning-class') warningClass: string = 'sticky-warning';
    @Input('dont-stick') notSticky: boolean = false;

    @Output() activated = new EventEmitter();
    @Output() deactivated = new EventEmitter();

    private onScrollBind: EventListener = this.onScroll.bind(this);
    private onResizeBind: EventListener = this.onResize.bind(this);

    private isStuck: boolean = false;
    private isWarning: boolean = false;

    private elem: any;
    private container: any;
    private originalCss: any;
    private originalPosition: any;

    private bodyScrollHeight: number;
    private windowHeight: number;
    private windowWidth: number;
    private containerHeight: number;
    private elemHeight: number;
    private containerStart: number;
    private warningStart: number;
    private fontpx: number;
    private scrollFinish: number;

    constructor(
        private element: ElementRef,
        public navService: NavService
    ) {
        this.elem = element.nativeElement;
        console.log(this.elem);
    }

    ngOnInit(): void {
        window.addEventListener('scroll', this.onScrollBind);
        window.addEventListener('resize', this.onResizeBind);
    }

    ngAfterViewInit(): void {

        // define scroll container as parent element
        this.container = this.elem.parentNode;

        this.originalCss = {
            zIndex: this.getCssValue(this.elem, 'zIndex'),
            position: this.getCssValue(this.elem, 'position'),
            height: this.getCssNumber(this.elem, 'height') || 'auto',
            top: this.getCssValue(this.elem, 'top'),
            right: this.getCssValue(this.elem, 'right'),
            left: this.getCssValue(this.elem, 'left'),
            bottom: this.getCssValue(this.elem, 'bottom')
        };

        this.originalPosition = {
            right: this.getBoundingClientRectValue(this.elem, 'right'),
            left: this.getBoundingClientRectValue(this.elem, 'left'),
            windowWidth: window.innerWidth,
        };

        this.defineDimensions();

        this.sticker();
    }

    ngOnDestroy(): void {
        window.removeEventListener('scroll', this.onScrollBind);
        window.removeEventListener('resize', this.onResizeBind);
    }

    onScroll(): void {
        if (this.bodyScrollHeight!==document.body.scrollHeight){
            this.onResize();
        }
        this.sticker();
    }

    onResize(): void {
        this.defineDimensions();
        if (this.isStuck && !this.notSticky) {   // adjusts width of stuck elements that are less than 100% w
            let resizeDiff = window.innerWidth/this.originalPosition.windowWidth;

            this.elem.style.left = resizeDiff * this.originalPosition.left + 'px';
            this.elem.style.right = this.windowWidth - resizeDiff * this.originalPosition.right + 'px';
            return;
        }
    }

    defineDimensions(): void {
        let containerTop: number = this.getBoundingClientRectValue(this.container, 'top');
        this.bodyScrollHeight = document.body.scrollHeight;
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
        this.fontpx = this.getCssNumber(this.elem, 'font-size')    //for multiplying by font-size to make sticky-start in REMs
        this.elemHeight = this.getCssNumber(this.elem, 'height');
        this.containerHeight = this.getCssNumber(this.container, 'height');
        this.containerStart = containerTop + this.scrollbarYPos() - this.offsetTop*this.fontpx - this.offsetAbsolute*this.fontpx + this.start*this.fontpx + this.startvh*this.windowHeight/100;
            //multiplying by font-size to make sticky-start in REMs
        this.warningStart = this.containerStart - (this.warning*this.fontpx) - (this.warningvh*(this.windowHeight/100));
        if (this.parentMode) {
            this.scrollFinish = this.containerStart - this.start*this.fontpx - this.offsetBottom*this.fontpx + (this.containerHeight - this.elemHeight);
        } if (this.scrollSpy) {
            this.scrollFinish = this.containerStart - this.start*this.fontpx - this.offsetBottom*this.fontpx + this.elemHeight;
        } else {
            this.scrollFinish = document.body.scrollHeight;            
        }
    }

    resetElement(): void {
        this.elem.classList.remove(this.stickClass);
        Object.assign(this.elem.style, this.originalCss);
    }
    warningElement(): void {
        this.isWarning = true;
        this.elem.classList.add(this.warningClass);
    }
    removeWarningElement(): void {
        this.isWarning = false;
        this.elem.classList.remove(this.warningClass);
    }

    stuckElement(): void {

        this.isStuck = true;
        this.elem.classList.remove(this.endStickClass);
        this.elem.classList.add(this.stickClass);

        if (!this.notSticky){
            let elementLeft = this.getBoundingClientRectValue(this.elem, 'left');
            let elementRight = this.getBoundingClientRectValue(this.elem, 'right');
            this.elem.style.zIndex = this.zIndex;
            this.elem.style.position = 'fixed';
            this.elem.style.top = this.offsetTop * this.fontpx + 'px';
            this.elem.style.right = 'auto';
            this.elem.style.left = elementLeft + 'px';
            this.elem.style.right = this.windowWidth - elementRight + 'px';
            this.elem.style.bottom = 'auto';
        };
        if (this.scrollSpy){
            this.navService.scrollSpy(this.elem.id);
            console.log(this.elem.id);
        }

        this.activated.next(this.elem);
    }

    unstuckElement(): void {
        this.isStuck = false;

        this.elem.classList.add(this.endStickClass);

        if (!this.notSticky){
            this.container.style.position = 'relative';
            this.elem.style.position = 'absolute';
            this.elem.style.top = 'auto';
            this.elem.style.right = 0;
            this.elem.style.left = 'auto';
            this.elem.style.bottom = this.offsetBottom * this.fontpx + 'px';
        };
        this.deactivated.next(this.elem);
    }

    matchMediaQuery(): any {
        if (!this.mediaQuery) return true;
        return (
            window.matchMedia('(' + this.mediaQuery + ')').matches ||
            window.matchMedia(this.mediaQuery).matches
        );
    }

    sticker(): void {

        // check media query
        if (this.isStuck && !this.matchMediaQuery()) {
            this.resetElement();
            return;
        }

        // detecting when a container's height changes
        let currentContainerHeight: number = this.getCssNumber(this.container, 'height');
        if (currentContainerHeight !== this.containerHeight) {
            this.defineDimensions();
        }

        let position: number = this.scrollbarYPos();

        // unstick
        if (this.isStuck && (position < this.containerStart || position > this.scrollFinish) || position > this.scrollFinish) {
            this.resetElement();
            if (position > this.scrollFinish) this.unstuckElement();
            this.isStuck = false;
        }
        // remove warning
        if (this.isWarning && position < this.warningStart) {
            this.removeWarningElement();
        }
        // warning
        if (this.isStuck === false && position > this.warningStart && position < this.scrollFinish) {
            this.warningElement();
        }
        
        // stick
        if (this.isStuck === false && position > this.containerStart && position < this.scrollFinish) {
            this.stuckElement();
        }
    }

    private scrollbarYPos(): number {
        return window.pageYOffset || document.documentElement.scrollTop;
    }

    private getBoundingClientRectValue(element: any, property: string): number {
        let result = 0;
        if (element.getBoundingClientRect) {
            let rect = element.getBoundingClientRect();
            result = (typeof rect[property] !== 'undefined') ? rect[property] : 0;
        }
        return result;
    }

    private getCssValue(element: any, property: string): any {
        let result: any = '';
        let style = element.currentStyle || window.getComputedStyle(element);        
        if (typeof style[property] !== 'undefined') {
            result = style[property];
        } else {
            result = style.getPropertyValue(property);
        }
        return result;
    }

    private getCssNumber(element: any, property: string): number {
        return parseInt(this.getCssValue(element, property), 10) || 0;
    }
}