import { Injectable } from '@angular/core';

@Injectable()
export class StatusCodeService {
    private _statusCode: number;

    getStatusCode() {
        return this._statusCode;
    }

    setStatusCode(newCode: number) {
        this._statusCode = newCode;
    }
}