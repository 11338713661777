import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { PaginationService } from './pagination.service';
import { PageEvent } from '@angular/material';

@Component({
    selector: 'pagination',
    template: `
        <mat-paginator [length]="_paginationService.totalEntries"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = $event; _paginationService.updatePage($event.pageIndex,$event.pageSize);">
        </mat-paginator>
    `,
    styles: [`
        .pagination-bottom-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 36px 0 36px 0;
        }
        .page-status {
            width: 49%;
        }
        .pagination {
            display: flex;
            justify-content: flex-end;
            width: 49%;
        }
        .pagination .btn { 
            border-radius: 0;
        }
        .page-numbers-wrapper {
            display: flex;
            justify-content: flex-end;
        }
        .page-numbers-wrapper .btn {
            color: #fff;
        }
        .page-numbers-wrapper .btn:hover {
            background-color: #333;
        }
        .onpage {
            background-color: #666;
        }
    `]
})
export class PaginationComponent implements OnInit, OnDestroy {
    firstEntryOnScreen: number = 0;
    lastEntryOnScreen: number = 0;
    entryRange: Subscription;
    // MatPaginator Inputs
    length;
    pageSize = 10;
    pageSizeOptions = [5, 10, 25, 100];

    // MatPaginator Output
    pageEvent: PageEvent;

    constructor(public _paginationService: PaginationService) {}

    ngOnInit() {
        this._paginationService.totalEntries = 0;
        this.firstEntryOnScreen = 0;

        this.entryRange = this._paginationService.entryRange
            .subscribe(range => {
                this.firstEntryOnScreen = range[0];
                this.lastEntryOnScreen = range[1];
            });
    }

    ngOnDestroy() {
        this.entryRange.unsubscribe();
    }
}