// import { Product } from './product';
// export const SOFTWARE: Product[] = [
export const SOFTWARE: any = 
{
    "name": "Find Your Software",
    "industries":[],
    "children": [
        {
            "name": "Full Product List",
            "industries":[],
            "children": [
                {
                    "name": "Accounting",
                    "children": [
                        {
                            "name":"Accounts Payable", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities"
                                ],
                            "route": "accounts-payable"
                        },
                        {
                            "name":"Accounts Receivable", 
                            "industries":[
                                " CommercialBanks "
                            ],
                            "route":"accounts-receivable-software"
                        },
                        {
                            "name":"Account Reconciliation", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"account-reconciliation"
                        },
                        {
                            "name":"Fixed Assets", 
                            "industries":[
                                " CommercialBanks ",
                                "Municipalities",
                                "RealEstate"
                            ],
                            "route":"fixed-assets-management"
                        },
                        {
                            "name":"General Ledger", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RealEstate"
                            ],
                            "route":"general-ledger"
                        },
                        {
                            "name":"Payment Processing", 
                            "industries":[
                                " CommercialBanks "
                            ],
                            "route":"payment-processing"
                        },
                        {
                            "name":"Purchasing", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"purchasing-software"
                        }
                    ],
                    "industries": [
                        " BrokerageFirms ",
                        " CentralBanks ",
                        " CommercialBanks ",
                        "Corporations",
                        "FundManagers",
                        "InvestmentManagers",
                        "InsuranceCompanies",
                        "Municipalities",
                        "RealEstate"
                    ]
                },
                {
                    "name": "Bank Operations",
                    "children": [
                        {
                            "name":"Cash Orders", 
                            "industries": [
                                " CentralBanks ",
                                " CommercialBanks "
                            ],
                            "route":"cash-order-application",
                        },
                        {
                            "name":"Documentary Collections", 
                            "industries": [
                                " CommercialBanks "
                            ],
                            "route":"documentary-collections-software",
                        },
                        {
                            "name":"Fed Funds", 
                            "industries": [
                                " CommercialBanks "
                            ],
                            "route":"fed-funds",
                        },
                        {
                            "name":"Forex Cashier", 
                            "industries":[
                                " CentralBanks ",
                                " CommercialBanks "
                            ],
                            "route":"forex-cashier",
                        },
                        {
                            "name":"Item Collection", 
                            "industries":[
                                " CommercialBanks "
                            ],
                            "route":"item-collections",
                        },
                        {
                            "name":"Safekeeping", 
                            "industries":[
                                " CommercialBanks "
                            ],
                            "route":"safekeeping-software",
                        }
                    ],
                    "industries": [
                        " CentralBanks ",
                        " CommercialBanks "
                    ]
                },
                {
                    "name": "Central Bank Operations",
                    "children": [
                        {
                            "name":"Auction Processing", 
                            "industries":[
                                " CentralBanks "
                            ],
                            "route":"central-bank-auction-processing",
                        },
                        {
                            "name":"Bond Issuance", 
                            "industries":[
                                " CentralBanks "
                            ],
                            "route":"central-bank-bonds",
                        },
                        {
                            "name":"Currency Management", 
                            "industries":[
                                " CentralBanks "
                            ],
                            "route":"central-bank-currency-management",
                        },
                        {
                            "name":"Dormant Accounts", 
                            "industries":[
                                " CentralBanks "
                            ],
                            "route":"central-bank-dormant-accounts",
                        },
                        {
                            "name":"ExchangeControl", 
                            "industries":[
                                " CentralBanks "
                            ],
                            "route":"central-bank-exchange-control",
                        },
                        {
                            "name":"Foreign Exchange Management", 
                            "industries":[
                                " CentralBanks "
                            ],
                            "route":"central-bank-forex",
                        },
                        {
                            "name":"Numismatic Management", 
                            "industries":[
                                " CentralBanks "
                            ],
                            "route":"central-bank-numismatic-management",
                        },
                        {
                            "name":"Portfolio Management for Central Bank", 
                            "industries":[
                                " CentralBanks "
                            ],
                            "route":"portfolio-management/central-bank-portfolio",
                        },
                        {
                            "name":"Treasury Bills", 
                            "industries":[
                                " CentralBanks "
                            ],
                            "route":"central-bank-treasury-bills",
                        },
                        {
                            "name":"Teller", 
                            "industries":[
                                " CentralBanks ",
                                " CommercialBanks "
                            ],
                            "route":"teller-software",
                        },
                        {
                            "name":"Web Interfaces for  CentralBanks ", 
                            "industries":[
                                " CentralBanks "
                            ],
                            "route":"central-bank-web-interfaces",
                        }
                    ],
                    "industries": [
                        " CentralBanks ",
                        " CommercialBanks "
                    ]
                },
                {
                    "name": "Investment Management",
                    "children": [
                        {
                            "name":"Back Office", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"back-office",
                        },
                        {
                            "name":"Bond Tax Swap", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "Corporations",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"bond-tax-swap",
                        },
                        {
                            "name":"Equity Trading", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "FundManagers",
                                "InvestmentManagers",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"equity-trading",
                        },
                        {
                            "name":"FOREX", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"forex-software",
                        },
                        {
                            "name":"Front Office", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"front-office",
                        },
                        {
                            "name":"Hedge Fund", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"hedge-fund",
                        },
                        {
                            "name":"Investment Calculator", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"investment-calculator",
                        },
                        {
                            "name":"Order Management", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "FundManagers",
                                "InvestmentManagers",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"order-management",
                        },
                        {
                            "name":"Portfolio Analysis", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"portfolio-analysis",
                        },
                        {
                            "name":"Portfolio Management",
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"portfolio-management",
                            "children": [
                                {
                                    "name":"FundPortfolio", 
                                    "industries":[
                                        " BrokerageFirms ",
                                        " CentralBanks ",
                                        " CommercialBanks ",
                                        "FundManagers",
                                        "InvestmentManagers",
                                        "InsuranceCompanies",
                                        "Municipalities",
                                        "RTA(Registrar/Transfer/PayingAgent)"
                                    ],
                                    "route":"portfolio-management/fund-portfolio"
                                },
                                {
                                    "name":"Treasury Portfolio", 
                                    "industries":[
                                        " CommercialBanks ",
                                        "InvestmentManagers"
                                    ],
                                    "route":"portfolio-management/treasury-portfolio"
                                },
                                {
                                    "name":"Customer Portfolio", 
                                    "industries":[
                                        " BrokerageFirms ",
                                        " CommercialBanks ",
                                        "InvestmentManagers",
                                        "InsuranceCompanies"
                                    ],
                                    "route":"portfolio-management/customer-portfolio",
                                }
                            ]
                        },
                        {
                            "name":"Precious Metals Management", 
                            "industries":[
                                " CommercialBanks ",
                                "InvestmentManagers"
                            ],
                            "route":"precious-metals-software",
                        },
                        {
                            "name":"Public Fund Deposit Collateralization", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"public-fund-deposit-collateralization",
                        },
                        {
                            "name":"Repo & Reverse Repo Agreement", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"repo-software",
                        },
                        {
                            "name":"Sales Commissions", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"sales-commissions",
                        },
                        {
                            "name":"Security Trading", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "InvestmentManagers"
                            ],
                            "route":"security-trading",
                        }
                    ],
                    "industries": [
                        " BrokerageFirms ",
                        " CentralBanks ",
                        " CommercialBanks ",
                        "Corporations",
                        "FundManagers",
                        "InvestmentManagers",
                        "InsuranceCompanies",
                        "Municipalities",
                        "RTA(Registrar/Transfer/PayingAgent)"
                    ]
                },
                {
                    "name": "Loans",
                    "children": [
                        {
                            "name":"Debt Collections", 
                            "industries":[
                                " CommercialBanks "
                            ],
                            "route":"debt-collections",
                        },
                        {
                            "name":"Loan Teller", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "FundManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"loan-cashier",
                        },
                        {
                            "name":"Loan Management", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "FundManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"loan-processing",
                        },
                        {
                            "name":"Loan Origination", 
                            "industries":[
                                " CommercialBanks "
                            ],
                            "route":"loan-origination",
                        }
                    ],
                    "industries": [
                        " BrokerageFirms ",
                        " CommercialBanks ",
                        "FundManagers",
                        "InsuranceCompanies"
                    ]
                },
                {
                    "name": "General Operations",
                    "children": [
                        {
                            "name":"Customer Relationship Management", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"crm-software-system",
                        },
                        {
                            "name":"FATCA CRM Option", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"fatca-crm-software-option",
                        },
                        {
                            "name":"Process Flow", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"process-flow-module",
                        },
                        {
                            "name":"Reconciliation", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "RealEstate",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"reconciliation",
                        }
                    ],
                    "industries": [
                        " BrokerageFirms ",
                        " CentralBanks ",
                        " CommercialBanks ",
                        "Corporations",
                        "FundManagers",
                        "InvestmentManagers",
                        "InsuranceCompanies",
                        "RealEstate",
                        "RTA(Registrar/Transfer/PayingAgent)"
                    ]
                },
                {
                    "name": "Real Estate",
                    "children": [
                        {
                            "name":"Real Estate", 
                            "industries":[
                                " CommercialBanks ",
                                "RealEstate"
                            ],
                            "route":"real-estate-software-system",
                        },
                        {
                            "name":"REO Management", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "FundManagers",
                                "InsuranceCompanies",
                                "RealEstate"
                            ],
                            "route":"reo-management",
                        },
                        {
                            "name":"REIT", 
                            "industries":[
                                " CommercialBanks ",
                                "FundManagers",
                                "InvestmentManagers",
                                "RealEstate",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"reit-software",
                        }
                    ],
                    "industries": [
                        " BrokerageFirms ",
                        " CommercialBanks ",
                        "FundManagers",
                        "InsuranceCompanies",
                        "InvestmentManagers",
                        "RealEstate",
                        "RTA(Registrar/Transfer/PayingAgent)"
                    ]
                },
                {
                    "name": "Trust",
                    "children": [
                        {
                            "name":"Annuities", 
                            "industries":[
                                " CommercialBanks ",
                                "InvestmentManagers",
                                "InsuranceCompanies"
                            ],
                            "route":"annuities-software",
                        },
                        {
                            "name":"Bond Paying Agent & Transfer Agent", 
                            "industries":[
                                " CommercialBanks ",
                                "Municipalities",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"bond-paying-agent",
                        },
                        {
                            "name":"CD Custody", 
                            "industries":[
                                " CommercialBanks "
                            ],
                            "route":"cd-custody",
                        },
                        {
                            "name":"CD Issuance", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "Corporations",
                                "Municipalities",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"cd-issuance",
                        },
                        {
                            "name":"Commercial Paper Issuance", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"commercial-paper-issuance",
                        },
                        {
                            "name":"DebtManagement", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "Corporations",
                                "Municipalities",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"debt-management",
                        },
                        {
                            "name":"IRA Management", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"ira-management",
                        },
                        {
                            "name":"LSA (Long-Term Savings Accounts)", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"long-term-savings-account-software",
                        },
                        {
                            "name":"Money Desk", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks "
                            ],
                            "route":"money-desk-software-system",
                        },
                        {
                            "name":"Mutual Fund", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"mutual-fund",
                        },
                        {
                            "name":"Open Time Deposit", 
                            "industries":[
                                " BrokerageFirms ",
                                " CommercialBanks ",
                                "Corporations",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"open-time-deposit",
                        },
                        {
                            "name":"Pension Management", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"pension-management",
                        },
                        {
                            "name":"Shareholder Accounting", 
                            "industries":[
                                " CommercialBanks ",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"shareholder-accounting",
                        },
                        {
                            "name":"eProxy Web Interface", 
                            "industries":[
                                " CommercialBanks ",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"eproxy-system",
                        }
                    ],
                    "industries": [
                        " BrokerageFirms ",
                        " CentralBanks ",
                        " CommercialBanks ",
                        "Corporations",
                        "FundManagers",
                        "InvestmentManagers",
                        "InsuranceCompanies",
                        "Municipalities",
                        "RTA(Registrar/Transfer/PayingAgent)"
                    ]
                },
                {
                    "name": "Services and Software Features",
                    "children": [
                        {
                            "name":"Consulting", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RealEstate",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"consulting-services",
                        },
                        {
                            "name":"Disaster Recovery", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RealEstate",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"disaster-recovery",
                        },
                        {
                            "name":"Email Option", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RealEstate",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"email-option-software-feature",
                        },
                        {
                            "name":"Interfaces", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RealEstate",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"mimics-interfaces",
                        },
                        {
                            "name":"Mobile Device Access", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RealEstate",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"mobile-device-access",
                        },
                        {
                            "name":"Outsourcing", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RealEstate",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"outsourcing-services",
                        },
                        {
                            "name":"Web Interfaces", 
                            "industries":[
                                " BrokerageFirms ",
                                " CentralBanks ",
                                " CommercialBanks ",
                                "Corporations",
                                "FundManagers",
                                "InvestmentManagers",
                                "InsuranceCompanies",
                                "Municipalities",
                                "RealEstate",
                                "RTA(Registrar/Transfer/PayingAgent)"
                            ],
                            "route":"web-interface",
                        }
                    ],
                    "industries": [
                        " BrokerageFirms ",
                        " CentralBanks ",
                        " CommercialBanks ",
                        "Corporations",
                        "FundManagers",
                        "InvestmentManagers",
                        "InsuranceCompanies",
                        "Municipalities",
                        "RealEstate",
                        "RTA(Registrar/Transfer/PayingAgent)"
                    ]
                }
            ]
        }
    ]
}