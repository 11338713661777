import { Component } from '@angular/core';
import { NavService } from '../services/nav.service';
import { SearchService } from '../shared/search.service';

@Component({
  selector: 'collapsed-nav',
  templateUrl: './templates/nav-collapsed-menu.html',
})
export class NavCollapsedMenuComponent {
    dropdownOpen: boolean = false;

    navItems = [
        {title:'Software', anchor:'#find-your-software'},
        {title:'Options', anchor:'#module-options'},
        {title:'How We Help', anchor:'#how-we-help'},
        {title:'About Us', anchor:'#about'},
    ];
    constructor(public navService: NavService, public searchService: SearchService) {}
    dropdownToggle(){
        console.log('entered');
        this.dropdownOpen = !this.dropdownOpen;
    }
    openContact(status){
        console.log('entered');
        this.navService.openContact(status);
        this.navService.changeFormContactType('Request a Demo');
        this.dropdownOpen = false;
    }
}