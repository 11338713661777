// general Angular module imports
import { NgModule } from '@angular/core';
import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

// component imports
import { AppComponent } from './app.component';
import { FourOhFourComponent } from './404/404.component';
import { LoadingComponent } from './loading-spinner/loading.component';
import { ModalComponent } from './modal.component';
import { NotificationComponent } from './notification/notification.component';
import { NavComponent } from './nav/nav.component';
import { NavCollapsedMenuComponent } from './nav/nav-collapsed-menu.component';
import { SharedModule } from './shared/shared.module';
import { SiteFooterComponent } from './site-footer/footer.component';
import { PrivacyPolicyComponent } from './site-footer/privacy-policy.component';
import { SearchComponent } from './search/search.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { Sticky } from './shared/stickydiv/ng2-sticky';

//directive imports
import { IfAutofocus } from './services/ngif-autofocus.directive';

// routing imports
import { routing } from './app.routes';
// import { ArchivedServiceandFeaturePagesRouting } from './archived-pages/archived-service-and-feature-pages.routes';
// import { ArchivedBankOpPagesRouting } from './archived-pages/archived-bank-op-pages.routes';
// import { ArchivedCBankOpPagesRouting } from './archived-pages/archived-cbank-op-pages.routes';
// import { ArchivedGeneralOpPagesRouting } from './archived-pages/archived-general-op-pages.routes';
// import { ArchivedInvestmentPagesRouting } from './archived-pages/archived-investment-pages.routes';
// import { ArchivedLoansPagesRouting } from './archived-pages/archived-loans-pages.routes';
// import { ArchivedRealEstatePagesRouting } from './archived-pages/archived-real-estate-pages.routes';

// services imports
import { LoadingService } from './loading-spinner/loading.service';
import { ModalService } from './services/modal.service';
import { NotificationService } from './notification/notification.service';
import { NavService } from './services/nav.service';
import { DataService } from './services/mimics-collateral.service';
import { SearchService } from './shared/search.service';
import { StatusCodeService } from './services/status-code.service';
import { PageScrollService } from 'ng2-page-scroll';

// Import package for browser versions detection
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';

// import {MatButtonModule, MatCheckboxModule} from '@angular/material';
// import {MatInputModule} from '@angular/material/input';
//  import {
    // MatAutocompleteModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    //  MatCheckboxModule,
    // MatChipsModule,
    //  MatDatepickerModule,
    //  MdDatepickerModule,
    //  MdNativeDateModule
    // MatDialogModule,
    // MatExpansionModule,
    // MatFormFieldModule,
    // MatGridListModule,
    // MatIconModule,
    // MatInputModule,
    // MatListModule,
    // MatMenuModule,
    // MatPaginatorModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    // MatRadioModule,
    // MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    // MatSnackBarModule,
    // MatSortModule,
    // MatTableModule,
    // MatTabsModule,
    // MatToolbarModule,
    // MatTooltipModule,
    // MatStepperModule,
//    } from '@angular/material';

@NgModule({
    imports: [
        BrowserModule, //.withServerTransition({appId: 'frontend'}), // - ssr?  doesn't yet work
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        routing,
        HttpModule,
        HttpClientModule,
        SharedModule,
        // MatDatepickerModule,
        // MatCheckboxModule,
    //     MdDatepickerModule,
    //  MdNativeDateModule,
        // MatInputModule,
        Ng2DeviceDetectorModule.forRoot()
        // MatButtonModule,
    ],
    declarations: [
        AppComponent,
        IfAutofocus,
        FourOhFourComponent,
        LoadingComponent,
        ModalComponent,
        NotificationComponent,
        NavComponent,
        NavCollapsedMenuComponent,
        NotificationComponent,
        SiteFooterComponent,
        PrivacyPolicyComponent,
        SearchComponent,
        SitemapComponent,
        Sticky
    ],
    providers: [
        LoadingService,
        ModalService,
        NotificationService,
        NavService,
        DataService,
        PageScrollService,
        SearchService,
        StatusCodeService,
        Title,
        Meta
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}