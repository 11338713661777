import { Component } from '@angular/core';

import { ModalService } from '../services/modal.service';

@Component({
  selector: 'site-footer',
  templateUrl: './templates/site-footer.html',
  styles: [`.footer-link { cursor: pointer; }`]
})
export class SiteFooterComponent {
  policyOpen: boolean = false;
  copyrightYear: number = new Date().getFullYear();

  constructor(public modalService: ModalService) {}

  policyClose(event){ this.policyOpen = event; };
  scrollToTop() {
    window.scrollTo(0, 0);
  }
}