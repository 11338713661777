
import {throwError as observableThrowError } from 'rxjs';
import CONFIG from '../../config';
import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { LoadingService } from '../loading-spinner/loading.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthService {
    public loggedIn: boolean;
    public AccountName: string = localStorage.getItem('AccountName') || sessionStorage.getItem('AccountName');
    public token: string = localStorage.getItem('token') || sessionStorage.getItem('token');
    public UserName: string = localStorage.getItem('UserName') || sessionStorage.getItem('UserName');   
    public UserID: string = localStorage.getItem('UserID') || sessionStorage.getItem('UserID');
    public DemoUserName: string = localStorage.getItem('DemoUserName') || sessionStorage.getItem('DemoUserName');
    public token1: string = localStorage.getItem('token1') || sessionStorage.getItem('token1');


    constructor(
        private _http: Http,
        private _router: Router,
        private _loadingService: LoadingService,
    ) {}

    // login(loginData): Observable<any> {
    //     console.log(loginData)
    //     this._loadingService.show();
    //     const body = JSON.stringify(loginData);
    //     // const headers={
    //     //     headers: new HttpHeaders({
    //     //         'Content-Type': 'application/json'
    //     //     })
    //     // }
    //     const headers = new Headers({
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     });
    //     const options = new RequestOptions({ headers: headers });

    //     return this._http.post(CONFIG.loginApiUrlBase, body, options)
    //         .map(response => {
    //             return response.json();
    //         })
    //         .catch(this.handleError)
    //         .finally(() => {
    //             this._loadingService.hide();
    //         });
    // }

    login(loginData): Observable<any> {
        this._loadingService.show();
        const body = JSON.stringify(loginData);
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        const options = new RequestOptions({ headers: headers });
        return this._http.post(CONFIG.loginApiUrlBase, body, options)
            .map(response => {
                return response.json();
            })
            .catch(this.handleError)
            .finally(() => {
                this._loadingService.hide();
            });
    }
    Demologin(loginData): Observable<any> {
        this._loadingService.show();
        const body = JSON.stringify(loginData);
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        const options = new RequestOptions({ headers: headers });
        return this._http.post(CONFIG.demoUserAccessLogin, body, options)
            .map(response => {
                return response.json();
            })
            .catch(this.handleError)
            .finally(() => {
                this._loadingService.hide();
            });
    }
    logout() {
        this._loadingService.show();
        this.loggedIn = false;
        
        this._http.get(CONFIG.loginApiUrlBase)
            .subscribe(response => {
                this._loadingService.hide();

                //if the user had the 'remember me' flag enabled, session info stored in localStorage
                if (localStorage.getItem('token') !== null) {
                    localStorage.removeItem('Expires/Max-Age');
                    localStorage.removeItem('token');
                    localStorage.removeItem('UserName');
                }

                //if the user did not have the 'remember me' flag enabled, session info stored in sessionStorage
                if (sessionStorage.getItem('token') !== null) {
                    sessionStorage.removeItem('token');
                    sessionStorage.removeItem('UserName');
                }

                //remove locally cached identity variables
                this.token = null;
                this.loggedIn = false;
                this.UserName = null;

                this._router.navigate(['/']);
            },
            error => {
                this.handleError(error);
            },
            () => {
                this._loadingService.hide();
            });
    }

    logout1() {
        this._loadingService.show();
        this.loggedIn = false;
        
        this._http.get(CONFIG.loginApiUrlBase)
            .subscribe(response => {
                this._loadingService.hide();

                //if the user had the 'remember me' flag enabled, session info stored in localStorage
                if (localStorage.getItem('token1') !== null) {
                    localStorage.removeItem('Expires/Max-Age');
                    localStorage.removeItem('token1');
                    localStorage.removeItem('UserID');
                    localStorage.removeItem('DemoUserName');
                }

                //if the user did not have the 'remember me' flag enabled, session info stored in sessionStorage
                if (sessionStorage.getItem('token1') !== null) {
                    sessionStorage.removeItem('token1');
                    sessionStorage.removeItem('UserID');
                    sessionStorage.removeItem('DemoUserName');
                }

                //remove locally cached identity variables
                this.token1 = null;
                this.loggedIn = false;
                this.DemoUserName = null;

                this._router.navigate(['/demo']);
            },
            error => {
                this.handleError(error);
            },
            () => {
                this._loadingService.hide();
            });
    }

    /*
    Utility error handler function for server errors
    */
    private handleError(error) {
        const errMsg = error.message || 'Server error';
        return observableThrowError(error);
    }
}