import {Injectable} from '@angular/core';

@Injectable()
export class LoadingService {
    loaderHidden: boolean = true;
    delay: number = 500; //delay before showing timer (default=500ms)
    timerHandle;

    constructor() {}

    show() {
        if(this.timerHandle || !this.loaderHidden) {
            return;
        }

        this.timerHandle = setTimeout(() => {
            this.loaderHidden = false;
        }, this.delay);
    }

    hide() {
        if(this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = undefined;
        }
        this.loaderHidden = true;
    }
}
