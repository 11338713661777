import {throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import { Response,   RequestOptions} from '@angular/http';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { AuthService } from './auth.service';
import { LoadingService } from '../loading-spinner/loading.service';
import { Request, ResponseContentType } from '@angular/http';
import { catchError,map } from 'rxjs/operators';
import CONFIG from './../../config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';



@Injectable()
export class BackendService {

    constructor(
        public _http: HttpClient,
        public _authService: AuthService,
        public _loadingService: LoadingService
    ) {}

    getData(url, showLoader = true) {        
        this._loadingService.show();
        const httpOptions = { 
            headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this._authService.token}`
            }),
             //responseType: "text"
        };       
        return this._http.get(url, httpOptions)
            .map(response => {
                if (response) {
                    return response;
                } else {
                    throw new Error('Error Getting Data');
                }
            })             
            .catch(this.handleError)            
            .finally(() => {
                this._loadingService.hide();
            })          
            
    }

    getUsersData(url) {
        this._loadingService.show();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this._authService.token1}`
            })
        };

        return this._http.get(url, httpOptions)
            .pipe(map(response => {
                if (response) {
                    console.log("TEST by error case in server");
                    console.log(response);

                    return response;
                } else {
                    throw new Error('Error Getting Data');
                }
            }))
        }

    getDemoData(url, showLoader = true) {        
        this._loadingService.show();
        const httpOptions = { 
            headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this._authService.token1}`
            }),
             //responseType: "text"
        };       
        return this._http.get(url, httpOptions)
            .map(response => {
                if (response) {
                    return response;
                } else {
                    throw new Error('Error Getting Data');
                }
            })             
            .catch(this.handleError)            
            .finally(() => {
                this._loadingService.hide();
            })
            
            
    }
    deleteData(url, showLoader = true) {
        this._loadingService.show();
        const httpOptions = { 
            headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this._authService.token}`
            })
        };
       // const httpOptions = new RequestOptions({ headers: headers });
        return this._http.delete(url, httpOptions)
            .map(response => {
                return response;
            })
            .catch(this.handleError)
            .finally(() => {
                this._loadingService.hide();
            });
    }

    postData(newData, url, showLoader = true) {
        console.log(newData);
        this._loadingService.show(); 
        const httpOptions = { 
            headers: new HttpHeaders({
                
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this._authService.token}`
            })
        };
       const body = JSON.stringify(newData);
       // const body = newData;
     
        console.log(url, body, httpOptions);
        return this._http.post(url, body, httpOptions)          
            .map(response => {
                console.log(url, body, httpOptions);
                return response;
            })
            .catch(this.handleError)
            .finally(() => {
                this._loadingService.hide();
            });
    }
    
    putData(updatedData, url, showLoader = true) {
        this._loadingService.show();
        const httpOptions = { 
            headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this._authService.token}`
            })
        };
        const body = JSON.stringify(updatedData);
        console.log(body);
        return this._http.put(url, body, httpOptions)
            .map(response => {
                return response;
            })
            .catch(this.handleError)
            .finally(() => {
                this._loadingService.hide();
            });
            
    }
    download(Url) {
        console.log(Url);
        return this._http.get(Url, {
            responseType: 'blob',
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }
    // getData(url, showLoader = true) {
    //     console.log('entered from single column component');
    //     const authToken = this._authService.token || localStorage.getItem('token') || sessionStorage.getItem('token');
    //     if(showLoader) { this._loadingService.show(); }

    //     const headers = new Headers({
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${authToken}`
    //     });
    //     const httpOptions = { 
    //         headers: new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${this._authService.token}`
    //         })
    //     };
       
    //     return this._http.get(url, httpOptions)   
       
    //     //const options: {  headers?: HttpHeaders } = { headers: headers };
    //     const options = new RequestOptions({ headers: headers });

    //     return this._http.get(url, options)
    //         .map(response => {
    //             if (response.json()) {
    //                 return response.json();
    //             } else {
    //                 throw new Error('Error Getting Data');
    //             }
    //         })
    //         .catch(this.handleError)
    //         .finally(() => {
    //             if(showLoader) { this._loadingService.hide(); }
    //         });
    // }

    // deleteData(url, showLoader = true) {
    //     const authToken = this._authService.token || localStorage.getItem('token') || sessionStorage.getItem('token');
    //     if(showLoader) { this._loadingService.show(); }

    //     const headers = new Headers({ 
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${authToken}`
    //     });

    //     const options = new RequestOptions({ headers: headers });

    //     return this._http.delete(url, options)
    //         .map(response => {
    //             return response.json();
    //         })
    //         .catch(this.handleError)
    //         .finally(() => {
    //             if(showLoader) { this._loadingService.hide(); }
    //         });
    // }

    // postData(newData, url, showLoader = true) {
    //     const authToken = this._authService.token || localStorage.getItem('token') || sessionStorage.getItem('token');
    //     if(showLoader) { this._loadingService.show(); }

    //     const headers = new Headers({
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${authToken}`
    //     });

    //     const options = new RequestOptions({ headers: headers });
    //     const body = JSON.stringify(newData);

    //     return this._http.post(url, body, options)
    //         .map(response => {
    //             return response.json();
    //         })
    //         .catch(this.handleError)
    //         .finally(() => {
    //             if(showLoader) { this._loadingService.hide(); }
    //         });
    // }

    // putData(updatedData, url, showLoader = true) {
    //     const authToken = this._authService.token || localStorage.getItem('token') || sessionStorage.getItem('token');
    //     if(showLoader) { this._loadingService.show(); };

    //     const headers = new Headers({
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${authToken}`
    //     });

    //     const options = new RequestOptions({ headers: headers });
    //     const body = JSON.stringify(updatedData);

    //     return this._http.put(url, body, options)
    //         .map(response => {
    //             return response.json();
    //         })
    //         .catch(this.handleError)
    //         .finally(() => {
    //             if(showLoader) { this._loadingService.hide(); }
    //         });
    // }

    /*
    Utility error handler function for server errors
    */
    private handleError(error) {
        console.log('backend service error, likely not logged in:', error);
        if(error.status && error.status === 401) {
            return observableThrowError('Session Expired- please log in');
        }

        return observableThrowError('Server Error');
    }

    // private HttpErrorResponse(err){      
    //         if (err.status >= 200 && err.status < 300) {
    //           const res = new HttpResponse({
    //             body: null,
    //             headers: err.headers,
    //             status: err.status,
    //             statusText: err.statusText,
    //             url: err.url
    //           });
    
    //           return Observable.of(res);
    //         } else {
    //           return Observable.throw(err);
    //         }
          
    // }
}