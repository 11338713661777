import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'privacy-policy',
    templateUrl: './templates/privacy-policy.html',
})
export class PrivacyPolicyComponent {
    @Input() policyOpen;
    @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();
    toggle(){
        this.change.emit(false);
        // this.policyOpen = false;
    }
}