import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';

import { Scroll } from './scroll/scroll';
// import { Sticky } from './stickydiv/ng2-sticky';
import { Ng2PageScrollModule } from 'ng2-page-scroll';

import { AuthService } from '../shared/auth.service';
import { BackendService } from '../shared/backend.service';

//import { PageScrollService } from 'ng2-page-scroll';
//import { FilterOptions } from '../shared/filter-options.component';
import { PaginationComponent } from './pagination.component';
//import { PaginationService } from './pagination.service';

// nav elements
//import { SubmoduleTabsComponent } from './nav/tabs.component';
import { ContactComponent } from '../homepage/contact-form/contact.component';
// import {
//     MatFormFieldModule,
//     MatIconModule,
//     MatInputModule,
//     MatSelectModule,
//     MatButtonModule,
//     MatDatepickerModule,
//     MatNativeDateModule,
//     MatCheckboxModule,
//     MatMenuModule,
//     MatPaginatorModule,    
// } from '@angular/material';
import { MaterialModule } from './material.module';
import { Pagination1Component } from './pagination1/pagination1.component';


@NgModule({
    imports: [ 
        CommonModule,
        Ng2PageScrollModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        // MatFormFieldModule,
        // MatIconModule,
        // MatInputModule,
        // MatSelectModule,
        // MatButtonModule,
        // MatDatepickerModule,
        // MatNativeDateModule,
        // MatCheckboxModule,
        // MatMenuModule,
        // MatPaginatorModule,
    ],
    declarations: [
        Scroll,
        PaginationComponent,
        Pagination1Component,
        ContactComponent
    ],
    exports: [ 
        Scroll,
        CommonModule, 
        Ng2PageScrollModule,
        FormsModule,
        ReactiveFormsModule,
        PaginationComponent,
        Pagination1Component,
        ContactComponent,
        MaterialModule,
        // MatFormFieldModule,
        // MatIconModule,
        // MatInputModule,
        // MatSelectModule,
        // MatButtonModule,
        // MatDatepickerModule,
        // MatNativeDateModule,
        // MatCheckboxModule,
        // MatMenuModule,
        // MatPaginatorModule,
    ],
    providers: [
        AuthService,
        BackendService
        //PageScrollService
    ]
})
export class SharedModule { }